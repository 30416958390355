export default {
  methods: {
    cleanEmail(email) {
      email = email.toLowerCase();
      email = email.replace(/\s/g, '');
      return email;
    },
    scrollToErrorSection($el) {
      // Scroll to the first element that has 'has-error' class.
      let elements = $el.getElementsByClassName('has-error');
      if (elements && elements.length > 0) {
        let el = $el.getElementsByClassName('has-error')[0];
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    updateUrlQueryNoRefresh(params, $route) {
      history.pushState(
        {},
        null,
        $route.path +
          '?' +
          Object.keys(params)
            .map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            })
            .join('&'),
      );
    },
    stripHtml(htmlText) {
      // Error document not found
      // let element = document.createElement("div");
      // element.innerHtml = htmlText;
      // return element.innerText;

      if (htmlText) {
        let regex = /(<([^>]+)>)/gi;
        return htmlText.replace(regex, '');
      }
      return htmlText;
    },
    validUrl(str) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      return !!pattern.test(str);
    },
    shortString(str, length = 100) {
      if (str && str.length > 0) return str.substring(0, length) + '...';
      return str;
    },
    intersect(a, b) {
      return a.filter(Set.prototype.has, new Set(b));
    },
    currencyFormat(amount) {
      if (amount) {
        let result = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        });
        return result.format(amount).replace(',00', '');
      } else {
        return null;
      }
    },
    dataURLToBlob(dataURL) {
      const [metadata, base64] = dataURL.split(',');
      const mime = metadata.match(/:(.*?);/)[1];
      const binary = atob(base64);
      let length = binary.length;
      const arrayBuffer = new Uint8Array(length);
      while (length--) {
        arrayBuffer[length] = binary.charCodeAt(length);
      }
      return new Blob([arrayBuffer], { type: mime });
    },
    range(size, startAt = 0) {
      return [...Array(size).keys()].map((i) => i + startAt);
    },
  },
};
